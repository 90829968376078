import React from "react";
import Head from "../../components/head.js";

import Layout from "../../components/layout";

const AnselmKiefer = () => {
  return (
    <Layout>
      <Head title="Anselm Kiefer" />
      <h3>Anselm Kiefer</h3>
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1659547429/artnow/anselm%20kiefer/anselm1.jpg"
        alt="Anselm Kiefer art"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1659547429/artnow/anselm%20kiefer/anselm2.jpg"
        alt="Anselm Kiefer art"
      />
    </Layout>
  );
};

export default AnselmKiefer;
